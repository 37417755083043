<template>
  <span
    class="leading-1em inline-flex items-center justify-center text-m-sm font-medium rounded-full w-4 h-4"
    :style="{
      backgroundColor: computedBackgroundColor,
      color: computedColor,
    }"
    >{{ computedAmount }}</span
  >
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getContrastYIQ } from "./Indicator.utils";

const props = defineProps<{
  amount: number;
  backgroundColor?: string;
}>();

const computedAmount = computed(() => props.amount);
const computedBackgroundColor = computed(
  () => props.backgroundColor || "black",
);

const computedColor = computed(() => {
  if (computedBackgroundColor.value !== "black") {
    return getContrastYIQ(computedBackgroundColor.value);
  }
  return "white";
});
</script>
